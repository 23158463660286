/*
 * @Author: xiaodongyu
 * @Date 2019-11-29 23:27:11
 * @Last Modified by: yunfengguo
 * @Last Modified time: 2024-05-29 15:34:59
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { upload } from 'qiniu-js';
import Web from '../resource/web';
import { boolOf } from '../util/enum';
import DefType from './def-type';
export const merge = (...args) => Object.assign({}, ...args);
export const required = (def) => merge(def, { required: true });
export const primaryStaticProps = { staticProps: { class: 'yqg-text-primary' } };
export const successStaticProps = { staticProps: { class: 'yqg-text-success' } };
export const warningStaticProps = { staticProps: { class: 'yqg-text-warning' } };
export const sizeLongProps = { props: { style: { maxWidth: '100%', width: '500px' } } };
export const fixedLeft = (def, width = 20) => merge(def, { column: { fixed: 'left', width } });
export const fixedRight = (def, width = 20) => merge(def, { column: { fixed: 'right', width } });
// ant-form-item-control-wrapper占全行
export const wholeRow = (def) => merge(def, { itemProps: { wrapperCol: { span: 24 } } });
export const getPre = (prefix) => suffix => `${prefix}${suffix}`;
export const clearFields = (fields) => ({ form }) => form.setFieldsValue(fields.reduce((acc, field) => (Object.assign(Object.assign({}, acc), { [field]: undefined })), {}));
export const getSdTextStaticProps = (checker = boolOf, success = true) => ({ value }) => {
    const valid = checker(value);
    return {
        class: {
            'yqg-text-success': success && valid,
            'yqg-text-danger': !valid
        }
    };
};
// yqg-simple-table的column默认不换行，此方法用来给长文本设置宽度和换行，放在staticProps里，slot不可用
export const genColumnWrapStyle = ({ minWidth, maxWidth, wrap = false }) => ({
    style: {
        display: 'inline-block',
        minWidth: minWidth ? `${minWidth}px` : 'inherit',
        maxWidth: maxWidth ? `${maxWidth}px` : 'inherit',
        whiteSpace: wrap ? 'normal' : null,
        wordBreak: wrap ? 'break-all' : null
    }
});
export const staticComp = (def) => merge(def, { component: 'def-value' });
export const dateTimeDef = { type: 'date', props: { showTime: true }, filter: 'dateTime' };
export const blockItem = { itemProps: { class: 'yqg-form-item-block' } };
export const hiddenItem = { itemProps: { class: 'yqg-form-item-hidden' } };
export const rangeItem = { label: ' ', itemProps: { class: 'yqg-form-item-range' } };
export const op = { field: 'op', label: 'common.op' };
export const id = { field: 'id', label: 'ID' };
export const time = { field: 'time', label: 'common.time', filter: 'dateTime' };
export const timeCreate = { field: 'timeCreate', label: 'common.timeCreate', filter: 'dateTime' };
export const timeUpdate = { field: 'timeUpdate', label: 'common.timeUpdate', filter: 'dateTime' };
export const timeCreated = merge(timeCreate, { field: 'timeCreated' });
export const timeUpdated = merge(timeUpdate, { field: 'timeUpdated' });
export const remark = { field: 'remark', type: 'textarea', label: 'common.remark' };
export const numberCommasFixed2 = { filter: 'numberCommasFixed2' };
export const generateColumnDefs = (tableTitle, extraDefMap) => tableTitle.map(({ value: field, label, cellFilter: filter }) => (Object.assign(Object.assign({ field,
    label,
    filter }, (DefType[filter] ? { type: filter } : {})), (extraDefMap && extraDefMap[field]))));
export const getCommonOptions = (_a) => {
    var { tableTitle, rowKey = 'id', op: flag = true, extraDefMap = {}, extraCols = [] } = _a, rest = __rest(_a, ["tableTitle", "rowKey", "op", "extraDefMap", "extraCols"]);
    return (Object.assign({ rowKey, colDefs: [
            ...generateColumnDefs(tableTitle, extraDefMap),
            ...(flag ? [op] : []),
            ...extraCols
        ] }, rest));
};
export const customRequest = (options) => __awaiter(void 0, void 0, void 0, function* () {
    const { file, onError, onProgress, onSuccess } = options;
    const { data: { body: token } } = yield Web.getToken();
    const observable = upload(file, null, token);
    return new Promise((resolve, reject) => {
        observable.subscribe({
            next: onProgress,
            error: err => {
                onError(err);
                reject(err);
            },
            complete: res => {
                onSuccess(res);
                resolve(res);
            }
        });
    });
});
export const qiniuFileDef = {
    type: 'file',
    props: { customRequest, listType: 'text', valueField: 'url', urlField: 'url' }
};
export const qiniuImageDef = {
    type: 'file',
    props: { customRequest, listType: 'picture', valueField: 'url', urlField: 'url' }
};
