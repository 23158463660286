import deepmerge from 'deepmerge';
import pl from './languages/pl';
import vant from './vant';
export default deepmerge.all([{
        zh: {
            send: '发送',
            welcome: '很高兴为您服务，请描述您的问题',
            detailText: '点击查看历史消息',
            recentMessage: '以下为本次消息',
            robotName: '借款宝贝',
            noHistory: '无历史对话',
            change: '换一换',
            evaluate: '评价',
            system: '系统',
            sendFail: '发送失败，点击重新发送',
            endQueue: '结束排队',
            copy: '复制',
            withdraw: '撤回',
            userWithdrawText: '您撤回了一条消息',
            customerWithdrawText: '客服撤回了一条消息',
            evaluation: '满意度评价',
            personsNum: '人数',
            robot: '机器人',
            customer: '人工客服',
            clickCopy: '请点击【复制】按钮，在浏览器打开链接下载附件',
            copySuccess: '复制成功',
            webTips: '为了更好的为您提供服务，需要您填写一下信息',
            name: '姓名',
            required: '必填',
            phone: '注册手机号',
            submit: '提交',
            phoneRight: '请输入有效的手机号码！',
            choose: '请选择您要咨询的业务类型',
            unknownError: '未知错误',
            internetError: '网络错误',
            online: '在线客服',
            toCustomer: '转人工',
            news: '【新消息】',
            newText: '新消息',
            typeError: '附件类型暂不支持上传',
            sizeError: '不得超过50M',
            reConnect: '正在重连，请稍后再试',
            empty: '{type}不能为空',
            userName: '用户名',
            userPhone: '手机号码',
            evaluateTip: '请对本次服务进行评价',
            resolvedTip: '是否解决了您的问题？',
            resolved: '已解决',
            unsolved: '未解决',
            thankTip: '感谢您的评价，每一次评价都是我们改进的机会',
            pleaseFill: '请填写服务意见',
            pleaseChoose: '请勾选标签',
            optional: '选填',
            index: '首页',
            more: '查看更多',
            noRecord: '无更多记录',
            historyRecord: '点击查看历史记录',
            remind: '催单',
            pleaseLeaveMessage: '请您留言',
            leaveMessageRecord: '服务记录',
            back: '返回',
            submitSuccess: '提交成功',
            remindSuccess: '催单成功',
            remindTip: '我们会尽快为您处理，请您耐心等待',
            leaveMessageDetail: '服务详情',
            date: '年月日',
            dateTime: '年月日时分',
            noLeaveMessageRecord: '暂无记录',
            completed: '已完成',
            processing: '处理中',
            viewDetail: '查看',
            description: '描述',
            pleaseDescription: '请描述一下您遇到的问题',
            confirm: '确认',
            reductionApplication: '减免申请',
            nextStep: '下一步',
            billForReduction: '减免账单',
            reductionReason: '减免原因',
            period: '期',
            cancel: '取消',
            pleaseEnterAReachableMobileNumber: '可以联系到您的手机号',
            detailedDescription: '详细说明',
            selectTheBillForReduction: '选择申请减免单的账单',
            orderNumber: '订单号：',
            loanDisbursementDate: '放款日：',
            repaymentDate: '还款日：',
            noBills: '暂无账单',
            selectTheReasonForReduction: '选择减免原因',
            serviceType: '服务类型',
            creationTime: '创建时间',
            copySuccessful: '复制成功',
            apply: '发起申请',
            onlyOneTips: '您仅有1次申请减免的机会，无法再次申请减免',
            pendingTips: '您有一笔申请正在审批中，一般7个工作日内审批完成',
            serviceTips: '如有疑问，请联系客服',
            approvalTips: '客服将会在7个工作日内完成审批',
            mobileTips: '期间请保持手机畅通，客服可能会联系您',
            checkServiceProgress: '查看服务进度',
            leaveTips: '离开页面超过{userTimeOut}分钟，可能会导致会话断开!',
            closeServe: '确定结束当前服务？',
            closeConversation: '结束会话'
        },
        id: {
            send: 'Kirim',
            welcome: 'Mulai Chat Disini',
            detailText: 'Klik untuk melihat riwayat pesan',
            recentMessage: 'Berikut berita yang tersedia',
            robotName: 'pinjaman sayang',
            noHistory: 'Tidak ada sejarah dialog',
            change: 'Mengubah',
            evaluate: 'Evaluasi',
            system: 'Sistem',
            sendFail: 'Gagal mengirim, klik Kirim Ulang',
            endQueue: 'Akhiri antrian',
            copy: 'Salin',
            withdraw: 'Menarik kembali',
            userWithdrawText: 'Pesan Anda telah ditarik kembali',
            customerWithdrawText: 'Pesan telah ditarik kembali oleh Customer Service',
            evaluation: 'Evaluasi kepuasan',
            personsNum: 'jumlah orang',
            robot: 'Robot',
            customer: 'Layanan pelanggan manual',
            clickCopy: 'Silakan klik tombol [Salin] untuk membuka tautan di browser serta mengunduh lampiran',
            copySuccess: 'Salin Sukses',
            webTips: 'Untuk memberikan Anda layanan yang lebih baik, Anda perlu mengisi informasi berikut',
            name: 'Nama',
            required: 'Wajib isi',
            phone: 'Nomor telepon yang didaftarkan',
            submit: 'Kirim',
            phoneRight: 'Silahkan masukkan nomor ponsel yang benar!',
            choose: 'Silakan pilih jenis bisnis yang ingin Anda tanyakan',
            unknownError: 'Kesalahan yang tidak diketahui',
            internetError: 'Kesalahan Jaringan',
            online: 'Layanan online',
            toCustomer: 'Alihkan ke Agent',
            news: '	[Pesan Baru]',
            newText: 'Pesan Baru',
            typeError: 'Jenis lampiran saat ini tidak mendukung pengunggahan',
            sizeError: 'Tidak boleh melebihi 50M',
            reConnect: 'Sambungkan kembali, coba lagi nanti',
            empty: '{type} tidak boleh kosong',
            userName: 'Nama Pengguna',
            userPhone: 'Nomor Ponsel',
            evaluateTip: 'Silakan berikan penilaian Anda',
            resolvedTip: 'Apakah masalah Anda telah terselesaikan?',
            resolved: 'Sudah',
            unsolved: 'Belum',
            thankTip: 'Terima kasih atas penilaian Anda, setiap penilaian akan meningkatkan kualitas layanan kami.',
            pleaseChoose: 'Silakan pilih kategori',
            pleaseFill: 'Silakan masukan pendapat anda',
            optional: 'Opsional',
            index: 'Halaman pertama',
            more: 'lihat lebih banyak',
            date: 'Tahun Bulan Tanggal',
            dateTime: 'Tahun Bulan Hari Jam Menit',
            reductionApplication: 'Pengajuan Keringanan',
            nextStep: 'Selanjutnya',
            billForReduction: 'Tagihan untuk Keringanan',
            reductionReason: 'Alasan Keringanan',
            period: 'periode',
            cancel: 'Batal',
            submit: 'Ajukan',
            pleaseEnterAReachableMobileNumber: 'Silakan masukkan nomor ponsel yang dapat dihubungi',
            detailedDescription: 'Detail Rincian',
            submitSuccess: 'Pengajuan berhasil',
            selectTheBillForReduction: 'Pilih Tagihan untuk melakukan Keringanan',
            orderNumber: 'Nomor kode pesanan:',
            loanDisbursementDate: 'Tanggal Pencairan Pinjaman:',
            repaymentDate: 'Tanggal Pengembalian Pinjaman:',
            confirm: 'Konfirmasi',
            noBills: 'Tidak ada tagihan',
            selectTheReasonForReduction: 'Silakan Pilih Alasan untuk Keringanan',
            serviceType: 'Jenis layanan',
            creationTime: 'Waktu pembuatan',
            copySuccessful: 'Salin berhasil',
            apply: 'Lakukan Pengajuan',
            onlyOneTips: 'Anda hanya memiliki satu kesempatan untuk mengajukan keringanan. Pengajuan selanjutnya tidak akan diterima.',
            pendingTips: 'Anda sedang memiliki 1 pengajuan dalam proses peninjauan,  akan diproses dalam waktu tujuh hari kerja.',
            serviceTips: 'Jika Anda memiliki pertanyaan, silakan hubungi Customer Service resmi Easycash.',
            approvalTips: 'Tim layanan pelanggan diharapkan menyelesaikan proses persetujuan dalam waktu 7 hari kerja.',
            mobileTips: 'Selama periode ini, harap tetap menjaga agar telepon seluler Anda dapat dihubungi, karena tim Customer Service kami akan menghubungi Anda.',
            checkServiceProgress: 'Periksa progres layanan'
        },
        es: {
            send: 'Enviar',
            welcome: 'Iniciar chat aquí',
            detailText: 'Haga clic para ver el mensaje historial',
            recentMessage: 'Este es el mensaje actual',
            robotName: 'Productos de préstamo',
            noHistory: 'No hay conversaciones historiales',
            change: 'Cambiar',
            evaluate: 'Comentario',
            system: 'Sistema',
            sendFail: 'Envío fallido, haga clic para volver a enviar',
            endQueue: 'Finalizar la cola',
            copy: 'Copiar',
            withdraw: 'Retirar',
            userWithdrawText: 'Ha retirado un mensaje',
            customerWithdrawText: 'El servicio de atención al cliente ha retirado un mensaje',
            evaluation: 'Evaluación de satisfacción',
            personsNum: 'Número de personas',
            robot: 'Robots',
            customer: 'Servicio manual de atención al cliente',
            clickCopy: 'Haga clic en el botón [Copiar] para abrir el enlace en el navegador y descargar el archivo adjunto',
            copySuccess: 'Copiado con éxito',
            webTips: 'Para servirle mejor,rellene la siguiente información',
            name: 'Nombre',
            required: 'Obligatorio',
            phone: 'Número de teléfono móvil registrado',
            submit: 'Enviar',
            phoneRight: 'Por favor, rellene un número de teléfono móvil válido.',
            choose: 'Por favor, seleccione el tipo de negocio para consultar',
            unknownError: 'Error desconocido',
            internetError: 'Error de red',
            online: 'Atención al cliente online',
            toCustomer: '¿Desea hablar con un asesor?',
            news: '[Nuevas noticias]',
            newText: 'Nuevas noticias',
            typeError: 'El tipo de archivo adjunto no se admite a subir por el momento',
            sizeError: 'No debe superar los 50M',
            reConnect: 'Reconectando, por favor, inténtelo de nuevo más tarde',
            empty: '{type} no puede estar vacío',
            userName: 'Nombre de usuario',
            userPhone: 'Número de teléfono móvil',
            evaluateTip: 'Califique este servicio, por favor',
            resolvedTip: '¿Se ha resuelto su problema?',
            resolved: 'Resuelto',
            unsolved: 'No resuelto',
            thankTip: 'Gracias por su comentario, cada evaluación es una oportunidad de mejorarnos',
            pleaseFill: 'Por favor, rellene los comentarios del servicio',
            pleaseChoose: 'seleccione la etiqueta',
            optional: 'Opcional',
            index: 'Inicio',
            more: 'ver detalles'
        },
        en: {
            send: 'Send',
            welcome: 'Please describe your questions.',
            detailText: 'Click to check history messages',
            recentMessage: 'The following message is sent.',
            robotName: 'Borrowing items',
            noHistory: 'No history session',
            change: 'Change',
            evaluate: 'Comment',
            system: 'System',
            sendFail: 'Fail to send the message, click to resend.',
            endQueue: 'End queuing',
            copy: 'Copy',
            withdraw: 'Recall',
            userWithdrawText: 'You recalled a message',
            customerWithdrawText: 'Customer service recalled a message',
            evaluation: 'Satisfaction comments',
            personsNum: 'Number of people',
            robot: 'Virtual agents',
            customer: 'Live agents',
            clickCopy: 'Please click [copy] and open the link in your browser to download the attachment.',
            copySuccess: 'Copy succeeded',
            webTips: 'To provide better service for you, your info is needed.',
            name: 'Name',
            required: 'Required',
            phone: 'Registered phone No.',
            submit: 'Submit',
            phoneRight: 'Please enter valid phone No.',
            choose: 'Please select the business type you want to consult',
            unknownError: 'Unknown error',
            internetError: 'Network error',
            online: 'Online customer service',
            toCustomer: 'Transfer to live agents',
            news: '[New messages]',
            newText: 'New messages',
            typeError: 'Attachment under the format is not yet supported to upload',
            sizeError: 'Not more than 50M',
            reConnect: 'Reconnecting, please try again later',
            empty: '{type} cannot be null',
            userName: 'User name',
            userPhone: 'Phone No. ',
            evaluateTip: 'Please comment the service',
            resolvedTip: 'Your problem completed?',
            resolved: 'Completed ',
            unsolved: 'Pending',
            thankTip: 'Thanks for your comment, every comment is an opportunity for us to improve service.',
            pleaseFill: 'Please fill in service advice',
            pleaseChoose: 'Please check the tag ',
            optional: 'Optional',
            index: 'Homepage',
            more: 'View more',
            noRecord: 'No more history',
            remind: 'reminder',
            pleaseLeaveMessage: 'Message',
            leaveMessageRecord: 'Record',
            back: 'back',
            submitSuccess: 'submit successfully',
            remindSuccess: 'remind successfully',
            remindTip: 'We will deal with it as soon as possible, please be patient.',
            leaveMessageDetail: 'Details',
            date: 'date',
            dateTime: 'date-time',
            noLeaveMessageRecord: 'no record',
            completed: 'completed',
            processing: 'processing',
            viewDetail: 'detail',
            description: 'describe',
            pleaseDescription: 'Please describe your questions.',
            confirm: 'confirm',
            leaveTips: 'If you leave the page for more than {userTimeOut} minutes, the session may be disconnected!',
            closeServe: 'Are you sure to end the current service?',
            closeConversation: 'End the session',
            evaluateSuccess: 'Evaluation successful',
            cancel: 'cancel',
            satisfaction: 'Service evaluation'
        },
        th: {
            send: 'ส่ง',
            welcome: 'ยินดีให้บริการค่ะ กรุณาระบุปัญหาของท่าน',
            detailText: 'คลิกดูข้อมูลย้อนหลัง',
            recentMessage: 'ข้อมูลปัจจุบันดังนี้',
            robotName: 'เงินกู้',
            noHistory: 'ไม่มีประวัติการพูดคุย',
            change: 'เปลี่ยน',
            evaluate: 'ประเมิน',
            system: 'ระบบ',
            sendFail: 'ส่งล้มเหลว คลิกเพื่อทำการส่งใหม่',
            endQueue: 'สิ้นสุดการรอคิว',
            copy: 'คัดลอก',
            withdraw: 'ดึงกลับ',
            userWithdrawText: 'คุณทำการดึงข้อความกลับ',
            customerWithdrawText: 'ฝ่ายบริการลูกค้าทำการดึงข้อความกลับ',
            evaluation: 'ประเมินความพึงพอใจ',
            personsNum: 'จำนวนคน',
            robot: 'แชทบอท',
            customer: 'พนักงานฝ่ายบริการลูกค้า',
            clickCopy: 'กรุณาคลิกที่ปุ่ม[คัดลอก] เปิดลิงก์ในเบราว์เซอร์ของคุณเพื่อดาวน์โหลดไฟล์แนบ',
            copySuccess: 'คัดลอกสำเร็จ',
            webTips: 'เพื่อการให้บริการที่ดียิ่งขึ้น จึงขอให้ท่านโปรดช่วยกรอกข้อมูลดังต่อไปนี้',
            name: 'ชื่อ',
            required: 'จำเป็น',
            phone: 'หมายเลขโทรศัพท์มือถือที่ใช้ลงทะเบียน',
            submit: 'ยื่น',
            phoneRight: 'กรุณาใส่หมายเลขโทรศัพท์มือถือที่ถูกต้อง!',
            choose: 'กรุณาเลือกหัวข้อที่ต้องการปรึกษา',
            unknownError: 'ข้อผิดพลาดที่ไม่รู้จัก',
            internetError: 'ข้อผิดพลาดของเครือข่าย',
            online: 'บริการออนไลน์',
            toCustomer: 'ผู้เชี่ยวชาญ',
            news: '【News】',
            newText: 'News',
            typeError: 'ประเภทไฟล์แนบไม่รองรับการอัปโหลดในขณะนี้',
            sizeError: 'ต้องไม่เกิน 50M',
            reConnect: 'กำลังเชื่อมต่อใหม่ โปรดลองอีกครั้งในภายหลัง',
            empty: '{type}ห้ามเว้นว่าง',
            userName: 'ชื่อผู้ใช้งาน',
            userPhone: 'หมายเลขโทรศัพท์มือถือ',
            evaluateTip: 'กรุณาให้คะแนนสำหรับการบริการครั้งนี้',
            resolvedTip: 'สามารถช่วยแก้ปัญหาของคุณได้หรือไม่?',
            resolved: 'แก้ไข',
            unsolved: 'ไม่ได้แก้ไข',
            thankTip: 'ขอบคุณสำหรับการประเมินของท่าน ทุกการประเมินจะเป็นประโยชน์ให้เราได้ปรับปรุงให้ดียิ่งขึ้น',
            pleaseFill: 'กรุณาให้ความคิดเห็นด้านการบริการ',
            pleaseChoose: 'กรุณาคลิกเลือก',
            optional: 'เลือกกรอก',
            index: 'หน้าหลัก',
            more: 'ดูเพิ่มเติม',
            noRecord: 'ไม่มีบันทึกเพิ่มเติม',
            historyRecord: 'คลิกเพื่อดูประวัติ',
            remind: 'แจ้งเตือน',
            pleaseLeaveMessage: 'กรุณาฝากข้อความ',
            leaveMessageRecord: 'บันทึกข้อความ',
            back: 'ย้อนกลับ',
            submitSuccess: 'ยื่นสำเร็จ',
            remindSuccess: 'แจ้งเตือนสำเร็จ',
            remindTip: 'เราจะทำการแก้ไขให้เร็วที่สุด โปรดอดใจรอสักครู่',
            leaveMessageDetail: 'รายละเอียดข้อความ',
            date: 'วันที่',
            dateTime: 'เวลา',
            noLeaveMessageRecord: 'ไม่มีบันทึกในขณะนี้',
            completed: 'เสร็จสิ้น',
            processing: 'กำลังดำเนินการ',
            viewDetail: 'ดูรายละเอียด',
            description: 'อธิบาย',
            pleaseDescription: 'กรุณาอธิบายถึงปัญหาของท่าน'
        },
        pl
    }, vant]);
