export const getVersionCode = ua => {
    // eslint-disable-next-line max-len
    const SDK_VERSION_REG = /(yqg|yqd|zebra|koi|yqdpesoagad|bizloan|idn_fin|yqdeasycash|mxOlacred|mxvivacredit|yqdCredmex|mxmayacash|YqdMabilis|YqdTha|YqdEasyPLN)\w*\/([\d.]+)/i;
    const matchResult = ua.match(SDK_VERSION_REG);
    const versionCode = matchResult && matchResult[2];

    if (!versionCode) return null;

    if (/\./.test(versionCode)) {
        return `${+versionCode.split('.').map(digit => digit.padStart(2, 0)).join('')}`;
    }

    return parseInt(versionCode, 10);
};

export const minVersion = (targetVersion = 0) => getVersionCode(window.navigator.userAgent) >= targetVersion;

export const ua = navigator.userAgent.toLowerCase();

export const isZebra = /zebra\//i.test(ua);

export const isPC = !/ios|iphone|ipod|ipad|android/.test(ua);

export const isAndroid = /android/.test(ua);

export const isIOS = /iphone|ipad|ipod/.test(ua);

export const isWebview = /yqg|yqd|easycash|idn_fin|zebra/i.test(ua);

export const isEasycashApp = /easycash|idn_fin/.test(ua);

export const isTouTiaoMiniProgram = /toutiaomicroapp/i.test(ua);

export const isWeiXin = /micromessenger\//i.test(ua);

export const isWechatMiniProgram = /miniprogram/i.test(ua) && isWeiXin;

export const isMiniProgram = isTouTiaoMiniProgram || isWechatMiniProgram;

export const treeToArray = (tree, childKey = 'children') => {
    const arr = [];
    const expanded = datas => {
        if (datas && datas.length) {
            datas.forEach(item => {
                arr.push(item);
                expanded(item[childKey]);
            });
        }
    };

    expanded(tree);

    return arr;
};

export const transArrToMap = array => {
    const map = {};

    const traverse = node => {
        const {key} = node;

        map[key] = node;

        if (node.children?.length) {
            node.children.forEach(traverse);
        }
    };

    array.forEach(traverse);

    return map;
};
