const TagPrefix = 'Chat';
const businessEventPrefix = 'Client_Chat_';

export default {
    BTNS_FOR_REPLY: {
        tag: `${TagPrefix}FashBtnsForReply`,
        businessEvent: `${businessEventPrefix}BtnsForReply`
    },
    BTNS_FOR_KNOWLEDGE: {
        tag: `${TagPrefix}FashBtnsForKnowledge`,
        businessEvent: `${businessEventPrefix}BtnsForKnowledge`
    },
    BTNS_FOR_KNOWLEDGE_EXPOSE: {
        tag: `${TagPrefix}BtnsForKnowledgeExpose`,
        businessEvent: `${businessEventPrefix}BtnsForKnowledgeExpose`
    },
    BTNS_FOR_TRANSFER: {
        tag: `${TagPrefix}BtnsForTransfer`,
        businessEvent: `${businessEventPrefix}BtnsForTransfer`
    },
    BTNS_FOR_SERVICELIST: {
        tag: `${TagPrefix}BtnsForServiceList`,
        businessEvent: `${businessEventPrefix}BtnsForServiceList`
    },
    BTNS_FOR_QUESTION_CHANGE: {
        tag: `${TagPrefix}BtnsForQuestionChange`,
        businessEvent: `${businessEventPrefix}BtnsForQuestionChange`
    },
    // 机器人消息-快捷操作按钮
    BTNS_FOR_ROBOT_FAST_REPLY: {
        tag: `${TagPrefix}BtnsForRobotFastReply`,
        businessEvent: `${businessEventPrefix}BtnsForRobotFastReply`
    },
    BTNS_FOR_ANSWER_MORE: {
        tag: `${TagPrefix}BtnsForAnswerMore`,
        businessEvent: `${businessEventPrefix}BtnsForAnswerMore`
    },
    BTNS_FOR_TICKET_CARD: {
        tag: `${TagPrefix}BtnsForTicektCard`,
        businessEvent: `${businessEventPrefix}BtnsForTicektCard`
    },
    BTNS_FOR_REMIND: {
        tag: `${TagPrefix}BtnsForRemind`,
        businessEvent: `${businessEventPrefix}BtnsForRemind`
    },
    BTNS_FOR_COMMENT_ENTRY: {
        tag: `${TagPrefix}BtnsForCommentEntry`,
        businessEvent: `${businessEventPrefix}BtnsForCommentEntry`
    },
    BTNS_FOR_CHOOSE_ORDER_SEND: {
        tag: `${TagPrefix}BtnsForChooseOrderSend`,
        businessEvent: `${businessEventPrefix}BtnsForChooseOrderSend`
    },
    BTNS_FOR_MARKETING: {
        tag: `${TagPrefix}BtnsForMarketing`,
        businessEvent: `${businessEventPrefix}BtnsForMarketing`
    },
    // 帮助中心-进入页面
    HELP_CENTER_ENTRY: {
        tag: `${TagPrefix}HelpCenterEntry`,
        businessEvent: `${businessEventPrefix}HelpCenterEntry`
    },
    // 帮助中心-在线客服入口
    BTNS_FOR_ONLINE_CUSTOMER_SERVICE_ENTRY: {
        tag: `${TagPrefix}BtnsForOnlineCustomerServiceEntry`,
        businessEvent: `${businessEventPrefix}BtnsForOnlineCustomerServiceEntry`
    },
    // 帮助中心-电话客服入口
    BTNS_FOR_CALL_CENTER_ENTRY: {
        tag: `${TagPrefix}BtnsForCallCenterEntry`,
        businessEvent: `${businessEventPrefix}BtnsForCallCenterEntry`
    },
    // 帮助中心-服务进度入口
    BTNS_FOR_SERVICE_PROGRESS_ENTRY: {
        tag: `${TagPrefix}BtnsForServiceProgressEntry`,
        businessEvent: `${businessEventPrefix}BtnsForServiceProgressEntry`
    },
    // 帮助中心-更换订单
    BTNS_FOR_CHANGE_ORDER: {
        tag: `${TagPrefix}BtnsForChangeOrder`,
        businessEvent: `${businessEventPrefix}BtnsForChangeOrder`
    },
    // 帮助中心-立即还款
    BTNS_FOR_IMMEDIATE_REPAYMENT: {
        tag: `${TagPrefix}BtnsForImmediateRepayment`,
        businessEvent: `${businessEventPrefix}BtnsForImmediateRepayment`
    },
    // 帮助中心-工具与服务系列按钮
    BTNS_FOR_TOOLS_AND_SERVICES: {
        tag: `${TagPrefix}BtnsForToolsAndServices`,
        businessEvent: `${businessEventPrefix}BtnsForToolsAndServices`
    },
    // 帮助中心-工具详情，点击工具中按钮后，弹起的内容中按钮
    BTNS_FOR_TOOLS_DETAIL_JUMP: {
        tag: `${TagPrefix}BtnsForToolsDetailJump`,
        businessEvent: `${businessEventPrefix}BtnsForToolsDetailJump`
    },
    // 帮助中心-问题tab
    BTNS_FOR_HELP_CENTER_QUESTION_TAB: {
        tag: `${TagPrefix}BtnsForHelpCenterQuestionTab`,
        businessEvent: `${businessEventPrefix}BtnsForHelpCenterQuestionTab`
    },
    // 帮助中心-问题
    BTNS_FOR_HELP_CENTER_QUESTION: {
        tag: `${TagPrefix}BtnsForHelpCenterQuestion`,
        businessEvent: `${businessEventPrefix}BtnsForHelpCenterQuestion`
    },
    // 帮助中心-快速通道弹窗
    BTNS_FOR_HELP_CENTER_FAST_TRACT: {
        tag: `${TagPrefix}BtnsForHelpCenterFastTrack`,
        businessEvent: `${businessEventPrefix}BtnsForHelpCenterFastTrack`
    },
    // 结束会话
    BTNS_FOR_CLOSE_CONCERSATION: {
        tag: `${TagPrefix}BtnsForCloseConversation`,
        businessEvent: `${businessEventPrefix}BtnsForCloseConversation`
    },
    // 弹窗中订单跳转还款计划
    ORDER_TO_REPAYMENT_PLAN: {
        tag: `${TagPrefix}OrderToRepaymentPlan`,
        businessEvent: `${businessEventPrefix}OrderToRepaymentPlan`
    },
    // 会话-还款订单弹窗-曝光
    EXPOSE_ORDER_REPAYMENT: {
        tag: `${TagPrefix}ExposeOrderRepayment`,
        businessEvent: `${businessEventPrefix}ExposeOrderRepayment`
    },
    // 国内-提前结清-曝光
    EXPOSE_EARLY_SETTLEMENT: {
        tag: `${TagPrefix}ExposeEarlySettlement`,
        businessEvent: `${businessEventPrefix}ExposeEarlySettlement`
    },
    // 国内 - 留言卡片 - 曝光
    TICKET_CARD_EXPOSE: {
        tag: `${TagPrefix}TicketCardExpose`,
        businessEvent: `${businessEventPrefix}TicketCardExpose`
    },
    // 海外客户中心-在线客服入口
    OVC_ONLINE_CUSTOMER_SERVICE_ENTRY: {
        tag: `${TagPrefix}OvcOnlineCustomerServiceEntry`,
        businessEvent: `${businessEventPrefix}OvcOnlineCustomerServiceEntry`
    },
    // 海外客户中心-猜你想问-分类
    OVC_GUESS_YOU_WANT_TO_ASK_CATEGORY: {
        tag: `${TagPrefix}OvcGuessYouWantToAskCategory`,
        businessEvent: `${businessEventPrefix}OvcGuessYouWantToAskCategory`
    },
    // 海外客户中心-猜你想问-问题
    OVC_GUESS_YOU_WANT_TO_ASK_QUESTION: {
        tag: `${TagPrefix}OvcGuessYouWantToAskQuestion`,
        businessEvent: `${businessEventPrefix}OvcGuessYouWantToAskQuestion`
    },
    // 海外客户中心-联系客服-问题
    OVC_CONTACT_CUSTOMER_SERVICE_QUESTION: {
        tag: `${TagPrefix}OvcContactCustomerServiceQuestion`,
        businessEvent: `${businessEventPrefix}OvcContactCustomerServiceQuestion`
    },
    // 海外客户中心-投诉
    OVC_COMPLAINT: {
        tag: `${TagPrefix}OvcComplaint`,
        businessEvent: `${businessEventPrefix}OvcComplaint`
    },
    // 国内帮助中心 - 问题详情按钮 - 曝光
    HELP_CENTER_QUESTION_DETAIL_BTN: {
        tag: `${TagPrefix}HelpCenterQuestionDetailBtn`,
        businessEvent: `${businessEventPrefix}HelpCenterQuestionDetailBtn`
    },
    // 国内帮助中心 - 问题详情按钮 - 点击
    HELP_CENTER_QUESTION_DETAIL_BTN_CLICK: {
        tag: `${TagPrefix}HelpCenterQuestionDetailBtnClick`,
        businessEvent: `${businessEventPrefix}HelpCenterQuestionDetailBtnClick`
    },
    LOG_LEAVE_MESSAGE_ENTER: {
        tag: `${TagPrefix}LogLeaveMessageEnter`,
        businessEvent: `${businessEventPrefix}LogLeaveMessageEnter`
    },
    LOG_LEAVE_MESSAGE_PRESUBMIT: {
        tag: `${TagPrefix}LogLeaveMessagePreSubmit`,
        businessEvent: `${businessEventPrefix}LogLeaveMessagePreSubmit`
    },
    LOG_LEAVE_MESSAGE_SUBMIT: {
        tag: `${TagPrefix}LogLeaveMessageSubmit`,
        businessEvent: `${businessEventPrefix}LogLeaveMessageSubmit`
    },
    WEBSOCKET_CONNECTED: {
        tag: `${TagPrefix}WebSocketConnected`,
        businessEvent: `${businessEventPrefix}WebSocketConnected`
    },
    INIT_WEBSOCKET: {
        tag: `${TagPrefix}InitWebSocket`,
        businessEvent: `${businessEventPrefix}InitWebSocket`
    },
};
